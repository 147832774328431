'use client';

import { debounce } from '../utils';
import { VideoAtom } from './video.atom';
import {
  ConfigurableVideoWidgetParams,
  VideoMetadata,
  VideoWindow,
  WidgetApiMethods,
  WidgetInstance,
} from './video.types';
import { Tracking, useAnalyticsTracking } from '@thrivent-web/analytics';
import { logger } from '@thrivent-web/logging-utils';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';

// --------------------------------------------------------------------------

// Docs: https://qumu.github.io/player-sdk/legacy/usage/

export const useVideo = ({
  videoId,
  elementId,
  widgetOptions,
  onVideoEnded,
  bindAnalytics = false,
}: {
  videoId: string;
  elementId?: string;
  widgetOptions?: ConfigurableVideoWidgetParams;
  onVideoEnded?: () => void;
  bindAnalytics?: boolean;
}) => {
  const uniqueId = `qumu-video-${videoId}${elementId ? '-' + elementId : ''}`;
  const videoWindow =
    typeof window === 'undefined' ? null : (window as VideoWindow);
  const { trackEvent } = useAnalyticsTracking();

  // Track each video widget & it's state with an atom
  const [widgets, setWidgets] = useAtom(VideoAtom);

  // This is just here as a stop gap for cases where you could try removing a video before you even loaded it
  const [isDestroying, setIsDestroying] = useState<boolean>(false);

  // Title & Duration are actually grabbed from the video post load
  const [videoTitle, setVideoTitle] = useState<string>('');
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const titleRef = useRef<string>();
  const durationRef = useRef<number>();
  titleRef.current = videoTitle;
  durationRef.current = videoDuration;

  // Grabs the current time of the video
  const getVideoDuration = (videoApi: WidgetApiMethods): Promise<number> => {
    return new Promise((resolve) => {
      videoApi.get('currentTime', (timeInMS) => {
        if (typeof timeInMS === 'number') {
          return resolve(timeInMS / 1000);
        }
        resolve(0);
      });
    });
  };

  // Analytics Util: Track when a video is paused - Either by ending, stopping, or being removed.
  const sendVideoEndedEvent = useCallback(
    async (videoApi: WidgetApiMethods) => {
      const duration = await getVideoDuration(videoApi);
      trackEvent({
        eventType: Tracking.videoEnded,
        eventInfo: {
          videoTitle: titleRef.current ?? '',
          videoGUID: videoId,
          value: duration?.toString(),
        },
      });
    },
    [trackEvent, videoId],
  );

  // This effect is for actions we need to bind without analytics. These may
  // Fire multiple times (due to multiple components using the hook)
  useEffect(() => {
    const videoApi = widgets[uniqueId]?.widget?.api;
    if (!videoApi) {
      return;
    }
    const endedBind = () => {
      onVideoEnded && onVideoEnded();
    };
    videoApi.bind('ended', endedBind);
    return () => {
      videoApi.unbind('ended', endedBind);
    };
  }, [onVideoEnded, uniqueId, widgets]);

  // This effect handles binding analytics.
  // Since we'll use this hook in multiple components for the same video,
  // We need to make sure only ONE is binding events for analytics, otherwise there will be duplicate calls
  useEffect(() => {
    if (!bindAnalytics) {
      return;
    }

    const videoApi = widgets[uniqueId]?.widget?.api;
    const isLoaded = widgets[uniqueId]?.isLoaded;

    if (!videoApi || !isLoaded) {
      return;
    }

    const loadBind = (videoMetadata: number | VideoMetadata | undefined) => {
      if (!videoMetadata || typeof videoMetadata === 'number') {
        return;
      }

      setWidgets((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev?.[uniqueId],
          isVideoEnded: false,
          isLoading: false,
          isLoaded: true,
        },
      }));

      const title = new DOMParser().parseFromString(
        videoMetadata.title,
        'text/html',
      )?.documentElement?.textContent;

      if (title) {
        setVideoTitle(title);
        setVideoDuration(videoMetadata.duration);
      }
    };

    const playBind = async () => {
      const duration = await getVideoDuration(videoApi);

      setWidgets((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev?.[uniqueId],
          isPlaying: true,
          isVideoEnded: false,
          isLoading: false,
        },
      }));

      trackEvent({
        eventType: Tracking.videoPlay,
        eventInfo: {
          videoTitle: titleRef.current ?? '',
          videoGUID: videoId,
          value: duration?.toString(),
        },
      });
    };

    const pauseBind = async () => {
      const currentDuration = await getVideoDuration(videoApi);
      if (
        durationRef?.current &&
        currentDuration > durationRef?.current / 1000
      ) {
        return;
      }

      trackEvent({
        eventType: Tracking.videoPause,
        eventInfo: {
          videoTitle: titleRef.current ?? '',
          videoGUID: videoId,
          value: currentDuration?.toString(),
        },
      });

      setWidgets((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev?.[uniqueId],
          isPlaying: false,
        },
      }));
    };

    const endedBind = () => {
      setWidgets((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev?.[uniqueId],
          isPlaying: false,
          isVideoEnded: true,
        },
      }));

      sendVideoEndedEvent(videoApi);
    };

    const volumeChangeBind = debounce((value, e) => {
      trackEvent({
        eventType: Tracking.videoVolumeChanged,
        eventInfo: {
          videoTitle: titleRef.current ?? '',
          videoGUID: videoId,
          value: (value as number).toString(),
        },
      });
    }, 800);

    if (isDestroying) {
      return () => {
        videoApi.unbind('load', loadBind);
        videoApi.unbind('play', playBind);
        videoApi.unbind('pause', pauseBind);
        videoApi.unbind('ended', endedBind);
        videoApi.unbind('volumechange', volumeChangeBind);
      };
    }

    videoApi.bind('load', loadBind);
    videoApi.bind('play', playBind);
    videoApi.bind('pause', pauseBind);
    videoApi.bind('ended', endedBind);
    videoApi.bind('volumechange', volumeChangeBind);
    return () => {
      videoApi.unbind('load', loadBind);
      videoApi.unbind('play', playBind);
      videoApi.unbind('pause', pauseBind);
      videoApi.unbind('ended', endedBind);
      videoApi.unbind('volumechange', volumeChangeBind);
    };
  }, [
    widgets,
    setWidgets,
    isDestroying,
    sendVideoEndedEvent,
    uniqueId,
    trackEvent,
    videoId,
    bindAnalytics,
  ]);

  // Handles playing the video, will also call the loader if not already loaded
  const playVideo = (timesRun = 0) => {
    //If for some reason the variable is still not there maybe because the CDN is down
    if (!videoWindow?.KV?.widget) {
      if (timesRun < 10) {
        setTimeout(() => playVideo(timesRun + 1), 500);
        return;
      }
      return logger.warn('There was an error trying to initialize Qumu Video');
    }

    let thisWidget = widgets?.[uniqueId]?.widget;
    if (!thisWidget || !widgets[uniqueId]?.widget?.[0]) {
      thisWidget = loadVideo();
    }

    if (!thisWidget) {
      return;
    }

    setWidgets((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev?.[uniqueId],
        isVideoEnded: false,
        isPlaying: true,
      },
    }));
    const qumuApi = thisWidget?.api;
    qumuApi?.command('play', [], () => {});
  };

  // Handles loading the video
  const loadVideo = () => {
    if (!videoWindow?.KV?.widget) {
      logger.warn('There was an error trying to initialize Qumu Video');
      return undefined;
    }

    setWidgets((prev) => ({
      ...prev,
      [uniqueId]: { ...prev?.[uniqueId], isLoading: true },
    }));
    const video = videoWindow.KV.widget({
      // Outstanding questions on QUMU api?:
      // How do we mute? Is there a setting for it?
      // (sometimes it mutes automatically on autoplay, but this seems inconsistent)
      // Is there a way to not show the interface at all on load?
      // Adjust volume on an already playing video?
      selector: '#' + uniqueId,
      type: 'playback',
      guid: videoId,
      autoLoad: true,
      autoPlay: true,
      loop: false,
      closeOverlayOnFinish: false,
      showInfoPanel: false,
      hideEmbedButton: true,
      hideDownloadButton: true,
      hideShareButton: true,
      player: {
        maxWidth: 1024,
      },
      ...(widgetOptions ? widgetOptions : {}),
    });

    if (!video) {
      logger.warn('There was an error initializing the video');
      return undefined;
    }

    setWidgets((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev?.[uniqueId],
        widget: video,
        isLoading: false,
        isLoaded: true,
      },
    }));
    return video;
  };

  // This allows us to stop the video remotely
  const stopVideo = async () => {
    const qumuApi = widgets?.[uniqueId]?.widget?.api;
    if (!qumuApi) {
      return;
    }

    if (!widgets[uniqueId]?.isVideoEnded) {
      await sendVideoEndedEvent(qumuApi);
    }

    setWidgets((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev?.[uniqueId],
        isPlaying: false,
      },
    }));
    qumuApi?.command('pause', [], () => {});
  };

  // This removes the video & cleans up it's resources
  const removeVideo = async () => {
    if (!videoWindow?.KV?.widget) {
      return logger.warn('There was an error removing the video: no widgets');
    }

    const widgetArr = videoWindow.KV?.widget?.widgets;
    const qumuApi = widgets?.[uniqueId]?.widget?.api;
    if (!qumuApi) {
      return;
    }
    setIsDestroying(true);
    if (!widgets[uniqueId]?.isVideoEnded) {
      await sendVideoEndedEvent(qumuApi);
    }
    qumuApi?.command('destroy', [], () => {});

    if (widgetArr?.length && widgets[uniqueId]) {
      const index = widgetArr.indexOf(
        widgets[uniqueId].widget as WidgetInstance,
      );
      if (index > -1) {
        widgetArr.splice(index, 1);
      }
    }
    if (widgets[uniqueId]?.widget?.[0]) {
      widgets[uniqueId].widget[0].innerHTML = '';
    }
    setWidgets((prev) => ({ ...prev, [uniqueId]: undefined }));
    setIsDestroying(false);
  };

  return {
    uniqueId,
    titleRef,
    isLoaded: widgets?.[uniqueId]?.isLoaded,
    isVideoEnded: widgets?.[uniqueId]?.isVideoEnded,
    isPlaying: widgets?.[uniqueId]?.isPlaying,
    isLoading: widgets?.[uniqueId]?.isLoading,
    isDestroying,
    loadVideo,
    playVideo,
    stopVideo,
    removeVideo,
  };
};
