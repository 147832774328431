import { RefObject, useEffect, useRef } from 'react';

export const useAnimateChildren = (
  ref: RefObject<HTMLElement>,
  animateClass: string,
) => {
  const runOnce = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // listen for element leaving the viewport
    const observer = new IntersectionObserver(
      ([entry]) => {
        for (let i = 0; i < entry.target.children.length; i++) {
          const child = entry.target.children[i];
          if (entry.isIntersecting && runOnce.current) {
            child.classList.add('animate__animated', animateClass);
          } else {
            child.classList.remove(...child.classList);
          }
        }

        // This ref prevents the animation from running
        // on page load so that we don't play an animation for
        // an element already on screen
        runOnce.current = true;
      },
      { threshold: 0.05 },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, animateClass]);
};
