'use client';

import { useVideo } from '../use-video';
import { VideoPlayer } from '../video-player';
import { VideoProps } from '../video.types';
import { Icon, svgIcons } from '@exploration/icons';
import * as Dialog from '@radix-ui/react-dialog';
import { ReactElement, useState } from 'react';
import styled from 'styled-components';

// --------------------------------------------------------------------------

const Video = styled(VideoPlayer)`
  aspect-ratio: 1024 / 576;
  position: relative;
  max-width: 40rem;
  background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};

  .kv-widget,
  .kv-playback-widget,
  .kv-player,
  .kv-viewer,
  .kv-video,
  .kv-kv-player,
  .kv-kv-player iframe {
    position: absolute;
    left: 0;
    top: 0;
    /* The snippet applies a bunch of inline heights and widths, so this is the only way to override them, unfortunately */
    width: 100% !important;
    height: 100% !important;
    background: none !important;
  }
`;

const DialogTitle = styled(Dialog.Title)`
  display: none;
`;

const DialogContent = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 40rem;
  z-index: 9999;
  background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy};
  border-radius: 8px;
  overflow: hidden;
`;

const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9998;
  background-color: ${(p) => p.theme.midwestColors.neutralDarkHeavy}80;
  cursor: pointer;
  backdrop-filter: blur(3px);
`;

const DialogCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;
`;

const DialogClose = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

// --------------------------------------------------------------------------

export const ModalVideoPlayer = ({
  titleText,
  triggerComponent,
  className,
  videoId,
  elementId,
  ...rest
}: VideoProps & {
  triggerComponent: ReactElement;
  titleText: string;
}) => {
  const { playVideo, removeVideo, isDestroying, titleRef } = useVideo({
    videoId,
    elementId,
  });
  const [open, setOpen] = useState(false);

  const handleOpenChange = async (current: boolean) => {
    if (!current) {
      await removeVideo();
    }
    if (!isDestroying) {
      setOpen(current);
    } else {
      setTimeout(() => setOpen(current), 500);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger
        asChild
        onClick={() => playVideo()}
        data-analytics-name={`video: ${titleText ?? 'untitled'}`}
      >
        {triggerComponent}
      </Dialog.Trigger>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent>
          <DialogCloseWrapper>
            <Dialog.Close asChild>
              <DialogClose
                aria-label={`Close Dialog: ${titleRef.current ?? 'untitled'}`}
              >
                <Icon icon={svgIcons.cross} />
              </DialogClose>
            </Dialog.Close>
          </DialogCloseWrapper>
          <DialogTitle>{`Video Dialog title: ${titleRef.current ?? 'untitled'}`}</DialogTitle>
          <Video videoId={videoId} elementId={elementId} {...rest} />
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
