'use client';

import { useAnimateChildren } from './use-animate-children';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode | ReactNode[];
  animateClass?: string;
};

const InlineParent = styled.div`
  display: inline-block;
`;

export const AnimateIn = ({
  children,
  animateClass = 'animate__bounceIn',
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useAnimateChildren(ref, animateClass);

  if (!Array.isArray(children)) {
    return (
      <InlineParent ref={ref}>
        <div>{children}</div>
      </InlineParent>
    );
  }

  return (
    <InlineParent ref={ref}>
      {children.map((child, index) => (
        <div
          /* eslint-disable-next-line react/no-array-index-key */
          key={`animate-in-${index}`}
          className={`animate__delay-${Math.min(index, 5)}s`}
        >
          {child}
        </div>
      ))}
    </InlineParent>
  );
};
