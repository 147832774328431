'use client';

import { useVideo } from './use-video';
import { VideoProps } from './video.types';
import { Spinner } from '@midwest/web/base';
import styled from 'styled-components';

// --------------------------------------------------------------------------

const VideoWrapper = styled.div`
  position: relative;
  aspect-ratio: 1024 / 576;
  width: 100%;
  max-width: 1024px;
`;

const LoadWrapper = styled.div`
  background-color: black;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoHolder = styled.div`
  position: relative;
  z-index: 1;

  * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// --------------------------------------------------------------------------

export const VideoPlayer = ({ className, videoId, elementId }: VideoProps) => {
  const { uniqueId, isLoading } = useVideo({
    videoId,
    elementId,
    bindAnalytics: true,
  });
  return (
    <VideoWrapper className={className}>
      {isLoading && (
        <LoadWrapper>
          <Spinner />
        </LoadWrapper>
      )}
      <VideoHolder id={uniqueId} />
    </VideoWrapper>
  );
};
